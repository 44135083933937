<template>
  <div class="dry-power">
    <moduleTitle title="流水线状态"></moduleTitle>
    <!-- 流水线1
    <div class="images">
      <img src="../../../../assets/digital-page/bigwork.png" alt="">
      <img src="../../../../assets/digital-page/gun.png" alt="">
      <img src="../../../../assets/digital-page/work.png" alt="">
      <img src="../../../../assets/digital-page/free.png" alt="">
      <img src="../../../../assets/digital-page/out.png" alt="">
    </div> -->
    <!-- 流水线2 -->
    <div class="imagestwo">
      <div class="box1">
        <img src="../../../../assets/digital-page/bigwork.png" alt="">
        <span>2</span>
        <p>流水线数量</p>
      </div>
      <img src="../../../../assets/digital-page/gun.png" alt="">
      <div class="box2">
        <img src="../../../../assets/digital-page/work.png" alt="">
        <span>流水线1</span>
        <span>工作中</span>
        <div class="circle" style="background-color: #99C327;"></div>
      </div>
      <div class="box3">
        <img src="../../../../assets/digital-page/free.png" alt="">
        <span>流水线2</span>
        <span>空闲中</span>
        <div class="circle" style="background-color: #ABBCC3;"></div>
      </div>

    </div>
  </div>
</template>

<script>
import moduleTitle from './../moduleTitle.vue';
export default {
  components: {
    moduleTitle
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(292vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #99C327, #119edb) 10 10;
  // opacity: 0.74;

  // .images {
  //   position: relative;
  //   top: companyH(50vh);
  //   width: companyW(482vw);
  //   height: companyH(116vh);
  //   display: flex;
  //   justify-content: space-around;

  //   align-items: center;

  //   img {
  //     width: companyW(63vw);
  //     height: companyH(40vh);
  //   }

  //   img:nth-child(1) {
  //     width: companyW(89vw);
  //     height: companyH(62vh);
  //   }

  //   img:nth-child(2) {
  //     width: companyW(3vw);
  //     height: companyH(117vh);
  //   }
  // }

  .imagestwo {
    position: relative;
    top: companyH(95vh);
    left: companyW(42vw);
    width: companyW(402vw);
    height: companyH(116vh);
    display: flex;
    justify-content: space-around;
    align-items: center;

    img:nth-child(2) {
      width: companyW(3vw);
      height: companyH(117vh);
    }

    .box1 {
      width: companyW(120vw);
      height: companyH(180vh);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      color: #fff;

      img {
        width: companyW(100vw);
        height: companyH(82vh);
        margin-top: 0;
        margin-bottom: 0;
      }

      span {
        font-size: companyW(24vw);
        color: #99C327;
        font-family: Source Han Sans CN;
        font-weight: bold;

      }

      p {
        font-size: companyW(20vw);
        color: #ffffff;
        font-family: Source Han Sans CN;
        font-weight: bold;
        // opacity: 0.5;
      }
    }

    .box2,
    .box3 {
      width: companyW(100vw);
      height: companyH(180vh);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      color: #fff;
      text-align: center;

      .circle {
        width: companyW(15vw);
        height: companyH(15vh);
        border-radius: 50%;

      }

      img {
        width: companyW(83vw);
        height: companyH(60vh);
        margin-top: companyH(20vh);
        margin-bottom: companyH(10vh);
      }

      span {
        margin-bottom: companyH(10vh);
      }

      span:nth-child(2) {
        font-size: companyW(18vw);
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
      }

      span:nth-child(3) {
        font-size: companyW(16vw);
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #99C327;
        // opacity: 0.5;
      }
    }

    .box3 {
      span:nth-child(3) {
        color: #fff;
      }
    }
  }
}
</style>
