<template>
    <div>
        <!-- 批次详情页 -->
        <el-dialog :visible.sync="flag" :show-close="false" @close="closemask" :modal="false">
            <dv-border-box-1 :color="['#99C327', '#D6E2B5']">
                <div class="toolBox">
                    <div class="toptitle">批次详情</div>
                    <div class="del" @click='flag = false'>X</div>
                    <div class="list">
                        <div class="xinxi">
                            <span>育秧批次</span>
                            <p>{{ tableobj.seedingBatch }}</p>
                        </div>
                        <div class="xinxi">
                            <span>育秧品种</span>
                            <p>{{ tableobj.seedingVariety }}</p>
                        </div>
                        <div class="xinxi">
                            <span>种子来源</span>
                            <p>{{ tableobj.seedSource }}</p>
                        </div>
                        <div class="xinxi">
                            <span>育秧盘数</span>
                            <p>{{ tableobj.plateAll }} 盘</p>
                        </div>
                        <div class="xinxi">
                            <span>自育盘数</span>
                            <p>{{ tableobj.plateMyself }} 盘</p>
                        </div>
                        <div class="xinxi">
                            <span>对外盘数</span>
                            <p>{{ tableobj.plateOut }} 盘</p>
                        </div>
                        <div class="xinxi">
                            <span>入库时间</span>
                            <p>{{ tableobj.seedingBeginTime }}</p>
                        </div>
                        <div class="xinxi">
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;预计出库时间 </span>
                            <p>{{ tableobj.seedingEndTimePredict }}</p>
                        </div>
                    </div>
                </div>
            </dv-border-box-1>
        </el-dialog>
    </div>
</template>

<script>
import { } from '@/api/seedlingApi/seedling.js';
export default {
  props: {
    flag: {
      type: Boolean,
      default: false
    },
    tableobj: {
      type: Object,
      default: () => {
        return {};
      }
    }

  },
  components: {},
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {
    closemask() {
      this.$emit('closemask', false);
    },

  }
};
</script>

<style scoped lang="scss">
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

* {
    font-family: Source Han Sans CN;
    font-size: companyW(18vw);
    box-sizing: border-box;
    color: #fff;
}

::v-deep .el-dialog {
    width: companyW(930vw);
    height: companyH(620vh);
    background-color: #000;
    border-radius: companyH(40vh);

    margin: 0 auto;

    .el-input__icon {
        line-height: companyH(30vh);
    }
}

.dv-border-box-1 {
    position: relative;
    width: companyW(930vw);
    height: companyH(620vh);
}

::v-deep .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
}

::v-deep .el-dialog__body {
    padding: 0;

}

::v-deep .el-dialog__header {
    padding: 0;
}



.toolBox {
    height: companyH(580vh);

    padding-left: companyW(80vw);
    padding-right: companyW(80vw);
    position: relative;

    .toptitle {
        width: 100%;
        text-align: center;
        font-size: companyW(30vw);
        padding-top: companyH(30vh);
        margin-bottom: companyH(50vh);
    }

    .del {
        position: absolute;
        top: companyH(30vh);
        right: companyH(30vh);
        width: companyW(40vw);
        height: companyH(40vh);
        text-align: center;
        line-height: companyH(35vh);
        border-radius: 50%;
        font-size: companyH(30vh);
        color: #000;
        font-weight: 500;
        background-color: #fff;

    }

    .list {
        position: absolute;
        left: companyW(145vw);
        top: companyH(102vh);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: companyH(480vh);

        .xinxi {
            width: companyW(300vw);
            height: companyH(100vh);
            text-align: center;
            position: relative;

            span {
                display: block;
                width: companyW(180vw);
                height: companyH(30vh);
                font-size: companyW(24vw);
                background: url('../../../../../assets/digital-page/juxing32.png') no-repeat left;
            }

            p {
                // width: companyW(280vw);
                height: companyH(30vh);
                text-align: left;
                padding-left: companyW(18vw);
                color: lightgreen;
                margin-top: companyH(10vh);
                font-size: companyW(20vw);

            }

            // div {
            //     position: absolute;
            //     right: companyW(110vw);
            //     top: companyH(8vh);
            //     font-size: companyH(14vh);
            //     font-family: Source Han Sans CN;
            //     font-weight: 400;
            //     color: #6D8A94;
            // }

        }
    }

}
</style>
