<template>
    <div class="middle">
        <div class="middle-nav">
            <!-- <navList :Option="navList" /> -->
            <!-- <div class="aa">
                <div class="title">报警信息</div>
                <el-carousel height="60px" indicator-position="none">
                    <el-carousel-item v-for="item, index in tablelist" :key="index"
                        style="display: flex; align-items: center;justify-content: center;"
                        <div style="display: flex;">
                            <div style="color: red; margin-right:10px">{{ item.info }}</div>
                            <div style="color:#fff">{{ item.date }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div> -->
        </div>



    </div>
</template>

<script>
import { getAlarm } from '../../../api/seedlingApi/seedling.js'

export default {

    created() {
        this.getList();
    },
    methods: {
        getList() {
            getAlarm({})
                .then((res) => {
                    this.tablelist = res.data
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    data() {
        return {
            tablelist: [],
        };
    },

};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

.middle {
    position: relative;
    width: companyW(916vw);
    height: companyH(978vh);
    overflow: hidden;
    background-image: url("../../../assets/digital-page/bgc2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .middle-nav {
        width: companyW(716vw);
        height: companyH(65vh);
        margin: 0 auto;
        margin-top: companyH(44vh);

        .title {
            position: absolute;
            color: yellow;
            font-size: companyH(30vh);
            top: companyH(15vh);
            left: companyW(400vw);
            z-index: 100;
        }

        .aa {
            width: companyW(600vw);
            height: companyH(60vh);
            margin: 0 auto;
            background-color: rgba(153, 195, 39, 0.5);
            font-size: companyH(20vh);

            // ::v-deep .el-carousel__container {
            //     height: companyH(60vh);
            // }

            // ::v-deep .el-carousel__item {
            //     // background-color: rgba(230, 150, 53, 0.5);
            //     height: companyH(60vh);

            // }
        }


    }



    .middle-tray {
        position: absolute;
        margin-left: companyW(-70vw);
        width: companyW(1092vw);
        height: companyH(230vh);
        z-index: 99999;
        background-image: url(../../../assets/drying-page/tray.png);
        background-size: contain;
        background-repeat: no-repeat;
    }


}

::-webkit-scrollbar {
    // 纵向滚动条和横向滚动条宽度
    width: companyW(10vw);
    height: companyH(10vh);
}

::-webkit-scrollbar-thumb {
    // 滚动条背景条样式
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #5b5f63;
}

::-webkit-scrollbar-track {
    // 滚动条按钮样式
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    background: #fff;
}
</style>
