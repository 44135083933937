<template>
  <div class="Right">
    <!-- 育秧总览右侧育秧情况 -->
    <div class="right-nav">
      <ModuleTitle title="育苗数字监控" />
      <!-- 暗室 -->
      <div class="anshi">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          @click="determine(item.id, index)"
        >
          <div class="tupian">
            <img
              :src="
                tupian === index
                  ? require('../../../../../assets/digital-page/anshi.png')
                  : require('../../../../../assets/digital-page/anshi2.png')
              "
              alt=""
              class="img1"
            />

            <span :style="{ color: tupian != index ? '#fff' : '#99c327' }">{{
              item.darkroomName
            }}</span>
          </div>
        </div>
      </div>
      <!-- 线条 -->
      <div class="line"></div>
      <!-- 批次 -->
      <div
        class="pici"
        v-for="(item, index) in anshiList"
        :key="index"
        v-if="mock"
      >
        <div class="box">
          <p>批次</p>
          <div @click="getdetails(item.seedingBatch)">
            {{ item.seedingBatch }}
          </div>
        </div>
        <div class="box">
          <p>入库时间</p>
          <div>{{ date }}</div>
        </div>
        <div class="box">
          <p>育秧盘数（盘）</p>
          <div>{{ item.plateCount }}</div>
        </div>
      </div>
      <!-- 默认数据 -->
      <div class="pici" v-if="!mock">
        <div class="box">
          <p>批次</p>
          <div @click="getdetails(anshiobj.seedingBatch)">
            {{ anshiobj.seedingBatch }}
          </div>
        </div>
        <div class="box">
          <p>入库时间</p>
          <div>{{ date }}</div>
        </div>
        <div class="box">
          <p>育秧盘数（盘）</p>
          <div>{{ anshiobj.plateCount }}</div>
        </div>
      </div>

      <!-- 线条 -->
      <div class="line1"></div>
      <!-- 环境因素 -->
      <div class="weather">
        <div>
          <img src="../../../../../assets/digital-page/2415(3).png" alt="" />
          <div class="box1">
            <span>{{ xinxiList.airTemperature }}°C</span>
            <p>空气温度</p>
          </div>
        </div>
        <div>
          <img src="../../../../../assets/digital-page/2415(1).png" alt="" />
          <div class="box1">
            <span>{{ xinxiList.airHumidity }}</span>
            <p>空气湿度</p>
          </div>
        </div>
        <div>
          <img src="../../../../../assets/digital-page/2415(2).png" alt="" />
          <div class="box1">
            <span>{{ xinxiList.airLight }}</span>
            <p>光照强度</p>
          </div>
        </div>
        <div>
          <img src="../../../../../assets/digital-page/2415(2).png" alt="" />
          <div class="box1">
            <span>{{ xinxiList.soilTemperature }}</span>
            <p>土壤温度</p>
          </div>
        </div>
        <div>
          <img src="../../../../../assets/digital-page/2415(1).png" alt="" />
          <div class="box1">
            <span>{{ xinxiList.soilHumidity }}</span>
            <p>土壤湿度</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 育秧总览右侧对比上年情况 -->
    <div class="right-tray">
      <ModuleTitle title="设备控制" />
      <div class="switch">
        <div class="control" v-for="item in shebeiList" :key="item.index">
          <img
            src="../../../../../assets/digital-page/xiaofangkuai.png"
            alt=""
          />
          <span>{{ item.switchName }}</span>
          <el-switch
            v-model="item.status"
            active-text="开"
            inactive-text="关"
            active-color="rgba(153,195,39,1)"
            inactive-color="rgba(153,195,39,0.4)"
          >
          </el-switch>
        </div>
      </div>
    </div>
    <Details :flag="flag" @closemask="closemask" :tableobj="tableobj"></Details>
  </div>
</template>

<script>
import ModuleTitle from '../../moduleTitle.vue';
import Details from './details.vue';
import {
  getAdarkroomList,
  getBatchByDarkId,
  getDarkroomById,
  getDeviceControlById,
  getPatchByDarkid,
  getDetailInfo
} from '@/api/seedlingApi/seedling.js';

export default {
  components: {
    ModuleTitle,
    Details
  },
  data () {
    return {
      flag: false,
      mock: false,
      tableobj: {},
      tabList: [],
      anshiList: '',
      xinxiList: '',
      shebeiList: [],
      tupian: '你好word',
      date: '',
      // 暗室id
      ID: '',
      // 默认数据
      anshiobj: {},
      anshiID: ''
    };
  },
  created () {
    this.getList();
  },
  methods: {
    // 点击批次获取详情页
    getdetails (name) {
      this.flag = true;
      this.getpatch(this.ID, name);
    },
    // 详情页回调函数
    closemask (item) {
      console.log(item, 11111);
      this.flag = item;
    },
    // 调用批次详情页接口
    getpatch (id, name) {
      getPatchByDarkid(id, name)
        .then(res => {
          console.log(res);
          this.tableobj = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取时间
    getTime (date) {
      var now = new Date(date);
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, '0');
      date = date.toString().padStart(2, '0');
      var defaultDate = `${ year }年${ month }月${ date }日`;
      this.date = defaultDate;
      console.log(this.Date, '时间');
    },
    getList () {
      getAdarkroomList({})
        .then(res => {
          this.tabList = res.data;
          this.anshiID = res.data[0].id;
          this.determine(this.anshiID, 0);
        })
        .catch(err => {
          console.log(err);
        });
    },
    determine (id, index) {
      console.log(id, index, 'qaweqweqweqweqweqweqw');
      this.tupian = index;
      this.ID = id;
      getBatchByDarkId({
        id: id
      })
        .then(res => {
          this.anshiList = res.data;
          this.anshiobj = res.data[0];
          this.anshiList.forEach(item => {
            this.getTime(item.beginTime);
          });
        })
        .catch(err => {
          console.log(err);
        });
      getDetailInfo({
        id: id
      })
        .then(res => {
          this.xinxiList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getDeviceControlById({
        id: id
      })
        .then(res => {
          this.shebeiList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

::-webkit-scrollbar {
  display: none;
}

.Right {
  position: relative;
  width: companyW(482vw);
  height: 100%;

  .right-nav {
    width: companyW(482vw);
    height: companyH(672vh);
    margin: 0 auto;
    background: rgba(0, 20, 36, 0.4);
    border: 1px solid;
    border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
    // opacity: 0.74;

    .anshi {
      position: relative;
      top: companyH(30vh);
      width: companyW(482vw);
      height: companyH(144vh);
      display: flex;
      // justify-content: space-around;
      align-items: center;
      overflow-x: auto;

      div {
        flex-shrink: 0;
        width: companyW(50vw);
        height: companyH(80vh);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: companyH(14vh);
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #fff;
        margin-left: companyW(40vw);

        .tupian {
          width: companyW(50vw);
          height: companyH(60vh);
          font-size: companyW(16vw);
          background-repeat: no-repeat;
          background-size: cover;
        }

        .img1 {
          width: companyW(50vw);
          height: companyH(60vh);
        }

        .img2 {
          width: companyW(33vw);
          height: companyH(40vh);
        }
      }

      div:hover {
        cursor: pointer;
      }
    }

    .line {
      position: absolute;
      top: companyH(174vh);
      left: companyW(23vw);
      width: companyW(438vw);
      height: companyH(2vh);
      background: url('../../../../../assets/digital-page/xian.png') no-repeat
        center;
    }

    .carBox {
      height: companyH(200vh);
    }

    .pici {
      position: absolute;
      // top: companyH(206vh);
      top: companyH(206vh);

      width: companyW(482vw);
      height: companyH(100vh);
      display: flex;
      // justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
      color: #fff;
      font-size: companyH(16vh);

      .box {
        width: companyW(130vw);
        height: companyH(60vh);
        margin-left: companyW(28vw);

        p {
          width: companyW(140vw);
          font-size: companyW(18vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #eff0f1;
          margin-bottom: companyH(10vh);
        }

        div {
          background: url('../../../../../assets/digital-page/juxing760.png')
            no-repeat center;
          background-size: contain;
          width: companyW(130vw);
          height: companyH(40vh);
          text-align: center;
          line-height: companyH(40vh);
          font-size: companyW(16vw);
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
        }
      }
    }

    .line1 {
      position: absolute;
      top: companyH(340vh);
      left: companyW(23vw);
      width: companyW(438vw);
      height: companyH(2vh);
      background: url('../../../../../assets/digital-page/xian.png') no-repeat
        center;
    }

    .weather {
      position: absolute;
      top: companyH(370vh);
      width: companyW(482vw);
      height: companyH(252vh);
      // overflow-y: auto; //之前
      display: flex;
      flex-wrap: wrap;
      // align-items: center;

      div {
        width: companyW(215vw);
        height: companyH(95vh);
        margin-left: companyW(20vw);
        // padding: companyH(10vh);
        text-align: center;
        display: flex;
        // align-items: center;
        background: url('../../../../../assets/digital-page/huisebgc.png')
          no-repeat center;

        img {
          width: companyW(70vw);
          height: companyH(73vh);
          margin-top: companyH(10vh);
          margin-left: companyH(10vh);
        }

        .box1 {
          width: companyW(86vw);
          height: companyH(50vh);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-top: companyH(20vh);

          span {
            font-size: companyW(22vw);
            font-family: Source Han Sans SC;
            font-weight: 500;
            color: #99c327;
          }

          p {
            font-size: companyW(20vw);
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }

      .weather::-webkit-scrollbar {
        width: companyW(2vw);
      }

      .weather::-webkit-scrollbar-track {
        box-shadow: inset 0 0 companyW(5vw) rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .weather::-webkit-scrollbar-thumb {
        background-color: #99c327;
        box-shadow: inset 0 0 companyW(5vw) rgba(0, 0, 0, 0.2);
        border-radius: companyW(5vw);
      }
    }
  }

  .right-tray {
    position: relative;
    width: companyW(482vw);
    height: companyH(302vh);
    margin-top: companyH(24vh);
    background: rgba(0, 20, 36, 0.4);
    border: companyW(1vw) solid;
    border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
    // opacity: 0.74;

    .switch {
      width: companyW(482vw);
      height: companyH(152vh);
      position: relative;
      top: companyH(30vh);
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: companyH(10vh);

      .control {
        width: companyW(223vw);
        height: companyH(50vh);
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-bottom: companyH(1vh) dashed rgba(153, 195, 39, 0.4);
        margin-left: companyW(5vw);

        img {
          width: companyW(16vw);
          height: companyH(16vh);
          margin-right: companyH(5vh);
        }

        span {
          width: companyW(82vw);
          height: companyH(16vh);

          font-size: companyW(16vw);
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: companyH(16vh);
        }

        ::v-deep .el-switch {
          width: companyW(120vw);
          height: companyH(30vh);

          span {
            width: companyW(10vw);
            height: companyH(16vh);
            font-size: companyW(16vw);
            color: #fff;
          }

          span:nth-child(3) {
            width: companyW(60vw);
            height: companyH(22vh);
          }
        }
      }
    }
  }
}
</style>
