<template>
    <div class="dyr-service">
        <!-- 标题 -->
        <Title class="dyr-service-title" njTitle="top-title" typeOf="digital-page" :title="address+'数字育苗'"></Title>
        <div class="dyr-service-content">
            <div class="dyr-service-left">
                <!-- 左侧流水线状态组件 -->
                <state></state>
                <!-- 左侧视频监控组件 -->
                <Video class="dyr-service-service" ></video>
                <!-- 左侧气象信息组件 -->
                <weather lass="dyr-service-service"></weather>
            </div>
            <!-- 中间模块 -->
            <div class="dyr-service-middle">
                <Mddle />
            </div>
            <!-- 右侧整体组件 -->
            <div class="dyr-service-right">
                <Right />
            </div>
        </div>
        <bottomNav class="middle-bottomNav" />
    </div>
</template>

<script>
import Title from './components/title.vue';
import state from '../seedling/components/seeding-controlView/state.vue';
import Video from '../seedling/components/seeding-controlView/video.vue';
import weather from '../seedling/components/seeding-controlView/weather.vue';
import Mddle from './middle/control.vue';
import bottomNav from './components/BottomNav.vue';
import Right from '../seedling/components/seeding-controlView/right/index.vue';
export default {
  mounted(){
    this.address = localStorage.getItem('address');
  },
  data(){
    return{
      address:'',

    };
  },
  components: {
    Title,
    state,
    Video,
    weather,
    Mddle,
    bottomNav,
    Right
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

.dyr-service {
    position: relative;
    width: companyW(1920vw);
    height: companyH(1080vh);
    box-sizing: border-box;
    // background-image: url(../../assets/digital-page/bgc-img.png);
    background-color: rgba(8, 27, 25);
    // background-size: cover;
    // background-repeat: no-repeat;

    .dyr-service-title {
        padding-top: companyH(8vh);
  width: companyW(1820vw);
  height: companyH(70vh);
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
    }

    .dyr-service-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .dyr-service-left {
            width: companyW(482vw);
            height: companyH(978vh);
            margin-left: companyW(20vw);

            .dyr-service-service {
                margin-top: companyH(21vh);
            }
        }

        .dyr-service-middle {
            width: companyW(916vw);
            height: companyH(978vh);
        }

        .dyr-service-right {
            margin-right: companyW(20vw);
            height: companyH(978vh);

        }
    }

    .middle-bottomNav {
        position: absolute;
        bottom: 0;
        left: companyW(562vw);
        background-image: url(../../assets/digital-page/b.png);
        background-size: center;
        background-repeat: no-repeat;
    }
}
</style>
