import request from '@/utils/request.js';

// 获取育秧订单接口
export function getSeedlingOrder(data, num) {
    return request({
        url: `/seedingOrder/getOrderList?isFinish=${data}&type=${num}&pageNum=&pageSize=`,
        method: 'get',
        data,
    });
}
// 获取保存育秧订单
export function saveSeedlingOrder(data) {
    return request({
        url: `/seedingOrder/save`,
        method: 'post',
        data,
    });
}
// 获取育秧服务能力和订单情况
export function getSeedlingOrderNum(data) {
    return request({
        url: `/seedingOrder/getSeedingOrderNum`,
        method: 'get',
        data,
    });
}
// 获取育秧计划
export function getSeedlingPlan(data) {
    return request({
        url: `/seedingView/seedingPlan`,
        method: 'get',
        data,
    });
}
// 育秧头部导航栏
export function getTop(params) {
    return request({
        url: '/seedingView/getTop',
        method: 'get',
        params,
    });
}

// 获取育秧能力基本信息
export function getInf(params) {
    return request({
        url: '/seedingView/getInf',
        method: 'get',
        params,
    });
}

// 累积育秧服务
export function getSeedingServer(params) {
    return request({
        url: '/seedingView/getSeedingServer',
        method: 'get',
        params,
    });
}
//
// 育秧情况
export function getSeedingCondition(params) {
    return request({
        url: '/seedingView/getSeedingCondition',
        method: 'get',
        params,
    });
}

// 获取当年育秧情况
export function getSeedingYear(params) {
    return request({
        url: '/seedingView/getSeedingYear',
        method: 'get',
        params,
    });
}

// 获取育秧批次
export function getSelect(params) {
    return request({
        url: `/seedingView/getSelect`,
        method: 'get',
        params,
    });
}
// 按批次查询育秧情况
export function getSeedingYearName(params) {
    return request({
        url: `/seedingView/getSeedingName?name=${params} `,
        method: 'get',
        params,
    });
}
// 查询品种详情情况
export function getSeedingVarietyName(params) {
    return request({
        url: `/seedingView/getSeedingVariety?name=${params} `,
        method: 'get',
        params,
    });
}
//  获取对比上年情况
export function getLastYear(params) {
    return request({
        url: '/seedingOrder/getLastYear',
        method: 'get',
        params,
    });
}

//  获取大屏气象检测点
export function getAirByName(params) {
    return request({
        url: '/air/getAirNames/',
        method: 'get',
        params,
    });
}
//  获取大屏气象显示信息
export function getByDeviceId(data) {
    return request({
        url: `/air/getAirByDeviceId/${data}`,
        method: 'get',
    });
}
// 获取暗室的详情(包含名称)
export function getAdarkroomList(params) {
    return request({
        url: '/darkroom/list',
        method: 'get',
        params,
    });
}

export function getBatchByDarkId(params) {
    return request({
        url: '/seedingView/getBatchByDarkId',
        method: 'get',
        params,
    });
}

// 根据暗室id获取暗室信息
export function getDarkroomById(params) {
    return request({
        url: '/darkroom/getDarkroomById',
        method: 'get',
        params,
    });
}
// 根据暗室id获取设备控制情况
export function getDeviceControlById(params) {
    return request({
        url: '/darkroom/getDeviceControlById',
        method: 'get',
        params,
    });
}
// 获取暗室里批次的详情页
export function getPatchByDarkid(data, name) {
    return request({
        url: `/seedingView/getPatchByDarkid?id=${data}&name=${name}`,
        method: 'get',
        data,
    });
}

// 查询农户信息
export function getFarmer(params) {
    return request({
        url: `/farmer/get?search=${params}`,
        method: 'get',
        params: params,
    });
}
// 根据农户id查询信息
export function getFarmerID(params) {
    return request({
        url: `/farmer/get/${params}`,
        method: 'get',
        params: params,
    });
}
// 查询农户农田位置
export function getFarmerField(params) {
    return request({
        url: `field/getFieldByFarmerId?farmerId=${params}`,
        method: 'get',
        params: params,
    });
}
// 根据农田ID查询信息
export function getFarmerFieldID(params) {
    return request({
        url: `field/get/${params}`,
        method: 'get',
        params: params,
    });
}

// 查询育秧品种
export function getVarirty(params) {
    return request({
        url: `/seedingVariety/get`,
        method: 'get',
        params,
    });
}
// 查询告警信息
export function getAlarm(params) {
    return request({
        url: `/seedingView/alarm`,
        method: 'get',
        params,
    });
}
// 获得育秧情况年份
export function getYear(params) {
    return request({
        url: `/seedingView/getYear`,
        method: 'get',
        params,
    });
}
// 查询育秧情况年份数据
export function getYearCondition(params) {
    return request({
        url: `/seedingView/getSeedingCondition?time=${params}`,
        method: 'get',
        params,
    });
}
// 根据暗室id获取暗室大棚环境信息
export function getDetailInfo(params) {
    return request({
        url: '/darkroom/getDetailInfo',
        method: 'get',
        params,
    });
}
// 监控
export function loginAndPlayView(params) {
    return request({
        url: '/hkCamera/loginAndPlayView',
        method: 'post',
        data: params,
    });
}
// id获取摄像机信息
export function getByDarkroomId(params) {
    return request({
        url: '/camera/getByDarkroomId',
        method: 'get',
        params,
    });
}

export function getYiShiUrl(params) {
    return request({
        url: '/camera/getYiShiUrl',
        method: 'get',
        params,
    });
}

export function getIdName(params) {
    return request({
        url: '/device/getIdName',
        method: 'get',
        params,
    });
}
export function getAirByDeviceId(params) {
    return request({
        url: '/air/getAirByDeviceId' + '/' + params,
        method: 'get',
        params,
    });
}

export function selectAll(params) {
    return request({
        url: '/purchaseOrder/selectAll',
        method: 'get',
        params,
    });
}
export function totalOrderAmount(params) {
    return request({
        url: '/purchaseOrder/totalOrderAmount',
        method: 'get',
        params,
    });
}
export function getVolumeOfBusiness(params) {
    return request({
        url: '/purchaseOrder/getVolumeOfBusiness',
        method: 'get',
        params,
    });
}

// 查询店铺总数和农资种类总量
export function getShopAndPurchase(params) {
    return request({
        url: '/purchaseShop/getShopAndPurchase',
        method: 'get',
        params,
    });
}
export function listShop(params) {
    return request({
        url: '/purchaseShop/listShop',
        method: 'get',
        params,
    });
}
export function purchaseCountB(params) {
    return request({
        url: '/purchaseShop/purchaseCountB',
        method: 'get',
        params,
    });
}
export function purchaseCountC(params) {
    return request({
        url: '/purchaseShop/purchaseCountC',
        method: 'get',
        params,
    });
}
export function getCountAndTongBi(params) {
    return request({
        url: '/purchaseOrder/getCountAndTongBi',
        method: 'get',
        params,
    });
}
export function getPurchaseType(params) {
    return request({
        url: '/purchaseOrder/getPurchaseType',
        method: 'get',
        params,
    });
}
export function getTypeCount(params) {
    return request({
        url: '/purchaseOrder/getTypeCount',
        method: 'get',
        params,
    });
}
