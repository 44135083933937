<template>
  <div
    class="title"
    :style="{
      'background-image': `url(${require('@/assets/' +
        typeOf +
        '/' +
        njTitle +
        '.png')})`
    }"
  >
    <div class="title-desc">
      {{ title }}
    </div>
    <div class="rightBgc anniu1" @click="jumpshouye">
      <img src="../../../assets/njfwimgs/shouye.png" class="imgBox" />
      <p>首页</p>
    </div>
    <a class="rightBgc anniu2" href="http://nsfwgl.ifasun.com" target="_blank">
      <img src="../../../assets/njfwimgs/houtai.png" class="imgBox" />
      <p>后台</p>
    </a>
    <div class="rightBgc anniu3" @click="toback">
      <img src="../../../assets/njfwimgs/quanping.png" class="imgBox" />
      <p>全屏</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    },
    // 图片名字
    njTitle: {
      type: String,
      default: ''
    },
    // 静态文件夹下文件名
    typeOf: {
      type: String,
      default: ''
    }
  },

  methods: {
    jumpshouye () {
      this.$router.push({
        path: '/'
      });
    },
    jumphoutai () {
      this.$router.push('http://nsfwgl.ifasun.com');
    },
    toback () {
      document.documentElement.requestFullscreen();
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.title {
 width: 100%;
 height: 100%;
 background-size: cover;
 overflow: hidden;
 .title-desc {
  margin-top: companyH(11vh);
  text-align: center;
  height: companyH(40vh);
  font-size: companyH(42vh);
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #fefefe;
  line-height: 100%;
 }
}
.rightBgc {
  width: companyW(90vw);
  height: companyH(30vh);
  background-color: #4a515a;
  border-radius: companyW(5vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 companyW(10vw) 0 companyW(10vw);

  .imgBox {
    width: companyW(24vw);
    height: companyH(24vh);
  }
  p {
    font-size: companyH(18vh);
    color: #fff;
  }
}
.anniu1 {
  position: absolute;
  left: companyW(10vw);
  top: companyH(8vh);
}
.anniu2 {
  position: absolute;
  left: companyW(134vw);
  top: companyH(8vh);
}
.anniu3 {
  position: absolute;
  right: companyW(10vw);
  top: companyH(8vh);
}
</style>
