<template>
  <!-- 底部导航栏组件 -->
  <div class="bottomNav">
    <!-- <div :class="{ 'bottomNav-Highlight-left': index == 1 }" class="left-common-style" @click="selectHighlight(1)">
      <span class="bottomNav-Highlight-item bottomNav-Highlight-desc">育秧总览</span>
    </div>
    <div :class="{ 'bottomNav-Highlight-right': index == 2 }" class="right-common-style" @click="selectHighlight(2)">
      <span class="bottomNav-Highlight-item bottomNav-Highlight-desc">育秧订单</span>
    </div>
    <div :class="{ 'bottomNav-Highlight-middle': index == 3 }" class="middle-common-style" @click="selectHighlight(3)">
      <span class="bottomNav-Highlight-item bottomNav-Highlight-desc">数字管控</span>
    </div> -->
    <!-- <div class="left" @click="selectHighlight(1)">

      <div class="img" v-if="currentInd == 1"></div>
      育秧总览
    </div>
    <div class="middle" @click="selectHighlight(2)">

      <div class="img" v-if="currentInd == 2"></div>
      育秧订单
    </div>
    <div class="right" @click="selectHighlight(3)">

      <div class="img" v-if="currentInd == 3"></div>
      数字管控
    </div> -->
    <div v-for="v in  navData " :key="v.id" class="every" @click="changeNav(v.id)"
      :style="{ fontSize: currentInd == v.id ? `22px` : `20px` }">
      <div class="img" v-if="currentInd == v.id"></div>
      {{ v.title }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentInd: "seedling-overview",
      navData: [
        { id: "seedling-overview", title: "育秧总览" },
        { id: "seedling-orderView", title: "育秧订单" },
        { id: "seedling-controlView", title: "数字管控" },
      ],
    };
  },
  // methods: {
  //   selectHighlight(value) {
  //     console.log(value, 'selectHighlight');
  //     if (this.value == 1) {
  //       this.currentInd = value;
  //       console.log(this.currentInd);
  //       console.log(this.$route.path);
  //       this.$router.push({ path: 'seedling-overview' })
  //     };

  //     if (value == 2) {
  //       this.currentInd = value;

  //       this.$router.push({ path: 'seedling-orderView' })
  //     }

  //     if (value == 3) {
  //       this.currentInd = value;
  //       this.$router.push({ path: 'seedling-controlView' })
  //     }
  //   }
  // }
  methods: {
    changeNav(ind) {
      if (this.currentInd == ind) return;
      this.currentInd = ind;
      this.$router.push({
        path: `${ind}`,
      });
    },
  },
  mounted() {
    console.log(this.$route);
    this.currentInd = this.$route.name;
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.bottomNav {
  width: companyW(814vw);
  height: companyH(79vh);
  position: relative;
  background: url("../../../assets/digital-page/b2.png") no-repeat center;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .every {
    position: relative;
    width: companyW(168vw);
    height: companyH(60vh);

    text-align: center;
    line-height: companyH(60vh);
    font-size: companyW(18vw);
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;

    .img {
      width: companyW(187.2vw);
      height: companyH(60vh);
      background: url("../../../assets/digital-page/currentInd.png") no-repeat center;
      position: absolute;
      top: companyH(-10vh);
      // opacity: 0.74;
    }
  }
}
</style>
