<template>
  <!-- 标题组件 -->
  <div class="module-title">
    <div class="module-title-desc">
      {{ title }}
    </div>
    <!-- 选项列表 -->
    <el-select v-model="currentTime" @change="handChange">
      <el-option
        v-for="item in TimeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { getAdarkroomList } from '../../../../api/seedlingApi/seedling';
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      TimeOptions:'',
      currentTime:''
    };
  },

  created() {
    this.getanshiList();
  },
  mounted() {


  },
  methods: {
    getanshiList() {
      getAdarkroomList()
        .then(res => {
          if (res.code == 1) {
            this.TimeOptions = res.data.map(item => {
              return {
                value: item.id,
                label: item.darkroomName,
              };
            });
            this.currentTime= this.TimeOptions[0].label;
            this.$emit('getdarkroomId', this.TimeOptions[0].value);

          }
        })
        .catch(err => {});
    },
    handChange(val) {

      this.$emit('getdarkroomId', this.currentTime);
    },
  },
};
</script>

<style  lang="scss" >
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../../assets/font/np4SotSdlbui.woff);
}

.module-title {
  position: absolute;
  top: companyH(-18vh);
  left: companyW(-22vw);
  width: companyW(500vw);
  height: companyH(109vh);
  background-image: url(../../../../assets/digital-page/module_title.png);
  background-size: cover;

  .module-title-desc {
    position: absolute;
    left: companyW(53vw);
    bottom: companyH(65vh);
    width: companyW(120vw);
    height: companyH(18vh);
    font-size: companyH(18vh);
    font-family: Source Han Sans CN;
    font-weight: 700;
    font-style: italic;
    color: #ffffff;
    line-height: companyH(22vh);
    text-shadow: 0px 0px 9px rgba(32, 22, 0, 0.45);
  }

  .el-select {
    position: absolute;
    right: companyW(30vw);
    top: companyH(25vh);
    background: #1e2801;
    // opacity: 0.8;
    z-index: 3;

    .el-input__inner {
      width: companyW(100vw);
      height: companyH(30vh);
      background: #1e2801;
      border-radius: 0;
      border: #8fc201 companyW(1vw) solid;
      font-size: companyW(18vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #c3e1ff;
    }

    .el-input__icon {
      line-height: companyH(30vh);
    }
  }
}
</style>
