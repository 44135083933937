<template>
  <!-- 标题组件 -->
  <div class="module-title">
    <div class="module-title-desc">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    }
  }
};
</script>

<style  lang="scss" >
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.module-title {
  position: absolute;
  top: companyH(-18vh);
  left: companyW(-22vw);
  width: companyW(500vw);
  height: companyH(109vh);
  background-image: url(../../../assets/digital-page/module_title.png);
  background-size: cover;

  .module-title-desc {
    position: absolute;
    left: companyW(53vw);
    bottom: companyH(65vh);
    width: companyW(120vw);
    height: companyH(18vh);
    font-size: companyH(20vh);
    font-family: Source Han Sans CN;
    font-weight: 700;
    font-style: italic;
    color: #FFFFFF;
    line-height: companyH(22vh);
    text-shadow: 0px 0px 9px rgba(32, 22, 0, 0.45);
  }
}
</style>
