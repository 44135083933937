<template>
  <div class="dry-lastYear">
    <moduleTitle title="气象信息"></moduleTitle>
    <div class="weatherlist">
      <div class="listli">
        <img
          src="../../../../assets/digital-page/kongqiwendu.png"
          alt=""
        /><span>{{ listData.airTemperature }}</span>
        <p>空气温度</p>
      </div>
      <div class="listli">
        <img
          src="../../../../assets/digital-page/kongqishidu.png"
          alt=""
        /><span>{{ listData.airHumidity }}</span>
        <p>空气湿度</p>
      </div>
      <div class="listli">
        <img
          src="../../../../assets/digital-page/guangzhao.png"
          alt=""
        /><span>{{ listData.airLight }}</span>
        <p>光照强度</p>
      </div>
      <div class="listli">
        <img src="../../../../assets/digital-page/shuifen.png" alt="" /><span>{{
          listData.airPrecipitation
        }}</span>
        <p>降水量</p>
      </div>
      <div class="listli">
        <img src="../../../../assets/digital-page/fengsu.png" alt="" /><span>{{
          listData.airSpeed
        }}</span>
        <p>风速</p>
      </div>
      <div class="listli">
        <img
          src="../../../../assets/digital-page/fengxiang.png"
          alt=""
        /><span>{{ listData.airDirection }}</span>
        <p>风向</p>
      </div>
      <div class="listli">
        <img
          src="../../../../assets/digital-page/daqiyali.png"
          alt=""
        /><span>{{ listData.airPressure }}</span>
        <p>大气压</p>
      </div>
      <!-- <div class="listli">
        <img src="../../../../assets/digital-page/fengli.png" alt="" /><span>{{
          listData.airPower
        }}</span>
        <p>风力</p>
      </div> -->
    </div>
    <!-- 选项列表 -->
    <el-select v-model="viceid" placeholder="请选择" @change="handChange">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import moduleTitle from './../moduleTitle.vue';
import Echart from '../../../../components/echarts/index.vue';
import {
  getAirByName,
  getByDeviceId,
  getIdName,
  getAirByDeviceId,
} from '@/api/seedlingApi/seedling.js';
// import { json } from 'stream/consumers';

export default {
  components: {
    moduleTitle,

    Echart,
  },
  data() {
    return {
      listData: {},
      id: '',
      options: [],
      viceid: '',
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      getIdName({
        tenantId: sessionStorage.getItem('tenantId'),
        category: 1,
      })
        .then(res => {
          this.options = res.data.map(item => {
            return {
              value: item.deviceId,
              label: item.deviceName,
            };
          });
          this.viceid = this.options[1].value;
          this.getdata(this.viceid);
        })
        .catch(err => {});
    },
    getdata(val) {

      getAirByDeviceId(val).then(res => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    handChange(val) {
      this.getdata(val);
    },
  },
};
</script>

<style  lang="scss" >
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-lastYear {
  position: relative;
  width: companyW(482vw);
  height: companyH(312vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #99c327, #119edb) 10 10;
  margin-top: companyH(22vh);
  // opacity: 0.74;

  .weatherlist {
    position: relative;
    top: companyH(60vh);
    width: companyW(482vw);
    height: companyH(260vh);
    display: flex;
    flex-wrap: wrap;

    .listli {
      width: companyW(100vw);
      height: companyH(90vh);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-left: companyW(15vw);

      img {
        width: companyW(40vw);
        height: companyH(40vh);
      }

      span {
        font-size: companyW(20vw);
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #03c495;
      }

      p {
        font-size: companyW(16vw);
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
.el-select {
  position: absolute;
  right: companyW(30vw);
  top: companyH(4vh);
  background: #1e2801;
  // opacity: 0.8;
  z-index: 3;

  .el-input__inner {
    width: companyW(100vw);
    height: companyH(30vh);
    background: #1e2801;
    border-radius: 0;
    border: #8fc201 companyW(1vw) solid;
    font-size: companyW(18vw);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #c3e1ff;
  }

  .el-input__icon {
    line-height: companyH(30vh);
  }
}
</style>
